import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VExpansionPanels,{attrs:{"accordion":""}},_vm._l((_vm.stations),function(station){return _c(VExpansionPanel,{key:station.id,staticClass:"stationItem",on:{"click":function($event){return _vm.open(station.name, $event)}}},[_c(VExpansionPanelHeader,[_c('div',{staticClass:"headerContent"},[_c('div',{staticClass:"icons"},[_c('FlexibleIcon',{attrs:{"station":station}})],1),_c('div',{staticClass:"itemText"},[_vm._v(" "+_vm._s(station.name)+" ")])])]),_c(VExpansionPanelContent,[_c('div',{staticClass:"stationInfos"},[(station.icon == 'train')?_c('span',[_vm._v("Train station")]):_vm._e(),(station.icon == 'bus')?_c('span',[_vm._v("Bus station")]):_vm._e(),(station.icon == 'tram')?_c('span',[_vm._v("Tram station")]):_vm._e(),(station.icon == null)?_c('span',[_vm._v("Unkown station type")]):_vm._e()]),_vm._v(" Upcoming Connections: "),(_vm.getStationBoard == null)?_c('div',{staticClass:"loadingSpinner"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey"}})],1):_vm._e(),(_vm.getStationBoard != null)?_c('div',{staticClass:"stationStationboard"},[_c(VExpansionPanels,_vm._l((_vm.getStationBoard.stationboard),function(connection){return _c(VExpansionPanel,{key:connection.name},[_c(VExpansionPanelHeader,{staticClass:"trainItem"},[_vm._v(" "+_vm._s(connection.category)+" "+_vm._s(connection.number)+" --> "+_vm._s(connection.to)+" "),_c('span',{staticClass:"trainTime"},[_vm._v(_vm._s(connection.stop.departure.substr(11, 5)))])]),_c(VExpansionPanelContent,[_c('TransportDetail',{attrs:{"transport":connection}})],1)],1)}),1)],1):_vm._e()])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }