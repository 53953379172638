import { render, staticRenderFns } from "./SavedConnections.vue?vue&type=template&id=2b389cfa&scoped=true"
import script from "./SavedConnections.vue?vue&type=script&lang=js"
export * from "./SavedConnections.vue?vue&type=script&lang=js"
import style0 from "./SavedConnections.vue?vue&type=style&index=0&id=2b389cfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b389cfa",
  null
  
)

export default component.exports