import { render, staticRenderFns } from "./ConnectionResults.vue?vue&type=template&id=ec153edc&scoped=true"
import script from "./ConnectionResults.vue?vue&type=script&lang=js"
export * from "./ConnectionResults.vue?vue&type=script&lang=js"
import style0 from "./ConnectionResults.vue?vue&type=style&index=0&id=ec153edc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec153edc",
  null
  
)

export default component.exports