import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[_c(VAppBar,{attrs:{"color":"#262626 accent-4","dark":""}},[_c('div',{staticClass:"brand"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../assets/logo.png"),"alt":"Logo"}})])]),_c(VToolbarTitle,[_vm._v("Transitlines")]),_c(VSpacer),_c('div',{staticClass:"nav-mobile"},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1)],1),_c(VNavigationDrawer,{staticStyle:{"position":"fixed","top":"0","right":"0","overflow-y":"scroll"},attrs:{"absolute":"","temporary":"","right":"","width":"300"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{attrs:{"active-class":"#262626 text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c(VListItem,{attrs:{"href":"/"}},[_c(VListItemTitle,[_vm._v("Home")])],1),_c(VListItem,{attrs:{"href":"/connections"}},[_c(VListItemTitle,[_vm._v("Connections")])],1),_c(VListItem,{attrs:{"href":"/stationBoard"}},[_c(VListItemTitle,[_vm._v("Stationboard")])],1),_c(VListItem,{attrs:{"href":"/settings"}},[_c(VListItemTitle,[_vm._v("Settings")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }